.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  height: 15vmin;
  pointer-events: none;
  margin-left: 5vh;
  margin-right: -5vh;
}

.ensemble-picture {
  height: 40vmin;
}

.navy-button {
  background-color: #00336F;
  color: white;
  margin-bottom: 4vh;;
}

.App-header {
  background-color: white;
  min-height: 15vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100vw;
}

.page-header {
  background-color: #00336F;
  color: white;
  padding: 1rem 0;
}

body {
  background-color: #C8E6F7;
  min-height: 50vh;
}

footer {
  min-height: 15vh;
  padding: 5vh;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

h1 {
  color: black;
}

.icon {
  margin: 2vh;
  height: 40px;
  width: 40px;
}

.season {
  background-color: #00336F;
  min-height: 5vh;
  color: white;
  align-items: center;
  padding: 5vh;
}

.acknowledgements {
  background-color: #00336F;
  max-height: 5vh;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.displays {
  max-width: 50%;
  margin: 5vh;
}

.outreach {
  display: flex;
  flex-direction: row;
  background-color: #FFFBDB;
  align-items: center;
  justify-content: space-evenly;
  color: black;
}

.big-image {
  width: 50vw;
  height: 20vw;
}

.icon {
  width: 50px;
  height: 50px;
  margin: 10px;
}

.youtube {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
